// @flow
import React from "react";
import {
  Show,
  TextField,
  EmailField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import UserLabels from "./labels";
import ArrayListField from "../../components/ArrayListField";

const userShow = (props) => (
  <Show title="Benutzer anzeigen" {...props}>
    <TabbedShowLayout>
      <Tab label="Informationen">
        <TextField source="username" label={UserLabels.username} />
        <TextField source="displayName" label={UserLabels.displayName} />
        <TextField source="provider" label={UserLabels.provider} />
        <EmailField source="email" label={UserLabels.email} />
        <ReferenceArrayField
          source="roles"
          reference="roles"
          label={UserLabels.roles}
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField
          showTime
          source="lastLoginAt"
          label={UserLabels.lastLoginAt}
        />
        <DateField showTime source="createdAt" label={UserLabels.createdAt} />
        <DateField showTime source="updatedAt" label={UserLabels.updatedAt} />
      </Tab>
      <Tab label="Effektive Berechtigungen">
        <TextField source="evaluatedRootOrgUnit" label="Default-Root" />
        <TextField
          source="evaluatedRootOrgUnits.overview"
          label="Overview-Root"
        />
        <TextField
          source="evaluatedRootOrgUnits.dashboard"
          label="Dashboard-Root"
        />
        <TextField
          source="evaluatedRootOrgUnits.stoppdetail"
          label="Stopp-Detail-Root"
        />
        <ArrayListField source="evaluatedPermissions" label="Permissions" />
        <ArrayListField source="evaluatedTypes" label="Werte" />
      </Tab>
      <Tab label="Eigene Berechtigungen">
        <TextField source="rootOrgUnit" label="Default-Root" />
        <TextField source="rootOrgUnits.overview" label="Overview-Root" />
        <TextField source="rootOrgUnits.dashboard" label="Dashboard-Root" />
        <TextField
          source="rootOrgUnits.stoppdetail"
          label="Stopp-Detail-Root"
        />
        <ArrayListField source="permissions" label="Permissions" />
        <ArrayListField source="types" label="Werte" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default userShow;
